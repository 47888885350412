import React, { useState, useEffect } from "react";
import Home from "./Home";
import Room from "./Room";
import "../crossword.css";

function App() {
  const [roomId, setRoomId] = useState<string | null>(null);

  useEffect(() => {
    const handleUrlChange = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const roomIdParam = urlParams.get("room");
      setRoomId(roomIdParam);
    };

    handleUrlChange(); // Initial check
    window.addEventListener("popstate", handleUrlChange);

    return () => {
      window.removeEventListener("popstate", handleUrlChange);
    };
  }, []);

  const navigateToRoom = (newRoomId: string) => {
    window.history.pushState({}, "", `?room=${newRoomId}`);
    setRoomId(newRoomId);
  };

  return (
    <div className="App">
      {roomId ? (
        <Room key={roomId} roomId={roomId} />
      ) : (
        <Home onRoomCreated={navigateToRoom} onRoomJoined={navigateToRoom} />
      )}
    </div>
  );
}

export default App;
