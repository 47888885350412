import React from "react";
import { useCrosswordLogic, type User } from "../hooks/useCrosswordLogic";
import { getUserColor } from "../utils/getUserColor";
import classNames from "classnames";
import VirtualKeyboard from "./VirtualKeyboard";

interface CrosswordGridProps {
  gridState: {
    size: number;
    cells: { value: string; isBlocked: boolean }[][];
  };
  gamePhase: "setup" | "play";
  updateCell: (
    row: number,
    col: number,
    cell: { value: string; isBlocked: boolean }
  ) => void;
  updateCursor: (row: number, col: number) => void;
  users: User[];
  currentUser: User | null;
}

const CrosswordGrid: React.FC<CrosswordGridProps> = ({
  gridState,
  gamePhase,
  updateCell,
  updateCursor,
  users,
  currentUser,
}) => {
  const {
    inputRefs,
    handleCellClick,
    handleKeyDown,
    highlightedCells,
    currentCell,
    direction,
    clueNumbers,
    handleMouseDown,
    handleMouseEnter,
    handleMouseUp,
  } = useCrosswordLogic({
    gridState,
    gamePhase,
    updateCell,
    updateCursor,
    users,
    currentUser,
  });

  const handleVirtualKeyPress = (key: string) => {
    if (currentCell) {
      const [row, col] = currentCell;
      const event = {
        key,
        preventDefault: () => {},
      } as React.KeyboardEvent;
      handleKeyDown(row, col, event);
    }
  };

  const isCellHighlighted = (rowIndex: number, colIndex: number) => {
    return highlightedCells.some(
      ([row, col]) => row === rowIndex && col === colIndex
    );
  };

  const isCellCurrent = (rowIndex: number, colIndex: number) => {
    return (
      currentCell && currentCell[0] === rowIndex && currentCell[1] === colIndex
    );
  };

  const getUserAtCell = (rowIndex: number, colIndex: number) => {
    return users.find(
      (user) =>
        user.cursorPosition &&
        user.cursorPosition.row === rowIndex &&
        user.cursorPosition.col === colIndex &&
        user.name !== currentUser?.name
    );
  };

  return (
    <div className="crossword-container">
      <div className="crossword-grid-wrapper">
        <div
          className="crossword-grid"
          style={{ gridTemplateColumns: `repeat(${gridState.size}, 1fr)` }}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {gridState.cells.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
              const userAtCell = getUserAtCell(rowIndex, colIndex);
              return (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={classNames("crossword-cell", {
                    "blocked-cell": cell.isBlocked,
                    "highlighted-cell": isCellHighlighted(rowIndex, colIndex),
                    "current-cell": isCellCurrent(rowIndex, colIndex),
                    horizontal:
                      isCellCurrent(rowIndex, colIndex) &&
                      direction === "horizontal",
                    vertical:
                      isCellCurrent(rowIndex, colIndex) &&
                      direction === "vertical",
                    "user-cursor": userAtCell,
                  })}
                  onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                  onMouseEnter={() => handleMouseEnter(rowIndex, colIndex)}
                >
                  {clueNumbers[`${rowIndex}-${colIndex}`] && (
                    <span className="clue-number">
                      {clueNumbers[`${rowIndex}-${colIndex}`].number}
                    </span>
                  )}
                  {gamePhase === "play" && !cell.isBlocked ? (
                    <input
                      ref={(el) => (inputRefs.current[rowIndex][colIndex] = el)}
                      type="text"
                      maxLength={1}
                      value={cell.value}
                      onChange={() => {}} // Controlled input
                      onKeyDown={(e) => handleKeyDown(rowIndex, colIndex, e)}
                      readOnly
                    />
                  ) : null}
                  {userAtCell && (
                    <div className="user-cursor-indicator">
                      <div
                        className="user-cursor-indicator__background"
                        style={{
                          backgroundColor: getUserColor(userAtCell.name, 0.3),
                        }}
                        title={userAtCell.name}
                      ></div>
                      <span
                        className="user-cursor-indicator__initials"
                        style={{
                          backgroundColor: getUserColor(userAtCell.name),
                        }}
                      >
                        {userAtCell.name}
                      </span>
                    </div>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>
      {gamePhase === "play" && (
        <VirtualKeyboard onKeyPress={handleVirtualKeyPress} />
      )}
    </div>
  );
};

export default CrosswordGrid;
