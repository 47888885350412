import React, { useState, useEffect, useCallback } from "react";
import usePartySocket from "partysocket/react";
import CrosswordGrid from "./CrosswordGrid";
import NameInput from "./NameInput";
import { getUserColor } from "../utils/getUserColor";
import { Logo } from "./Logo";

interface RoomProps {
  roomId: string;
}

interface GridState {
  size: number;
  cells: { value: string; isBlocked: boolean }[][];
  phase: "setup" | "play";
}

interface User {
  id: string;
  name: string;
  cursorPosition: { row: number; col: number } | null;
}

const Room: React.FC<RoomProps> = ({ roomId }) => {
  const [gridState, setGridState] = useState<GridState | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userName, setUserName] = useState<string>(() => {
    return localStorage.getItem("crosswordUserName") || "";
  });

  const socket = usePartySocket({
    host: window.location.host,
    room: roomId,
    onMessage(event: MessageEvent) {
      const data = JSON.parse(event.data);
      if (data.type === "init" || data.type === "update") {
        setGridState(data.state);
        setUsers(data.users);
      } else if (data.type === "usersUpdate") {
        setUsers(data.users);
      } else if (data.type === "userInfo") {
        setCurrentUser(data.user);
        setUserName(data.user.name);
        localStorage.setItem("crosswordUserName", data.user.name);
      }
    },
  });

  const sendSetNameMessage = useCallback(
    (name: string) => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: "setName", name }));
      }
    },
    [socket]
  );

  useEffect(() => {
    if (
      socket.readyState === WebSocket.OPEN &&
      userName &&
      (!currentUser || currentUser.name !== userName)
    ) {
      sendSetNameMessage(userName);
    }
  }, [socket.readyState, userName, currentUser, sendSetNameMessage]);

  const handleNameSubmit = (name: string) => {
    setUserName(name);
    sendSetNameMessage(name);
  };

  const startPlaying = () => {
    socket.send(JSON.stringify({ type: "setPhase", phase: "play" }));
  };

  const handleClearGrid = () => {
    socket.send(JSON.stringify({ type: "clearGrid" }));
  };

  const updateCell = (
    row: number,
    col: number,
    cell: { value: string; isBlocked: boolean }
  ) => {
    socket.send(JSON.stringify({ type: "updateCell", row, col, cell }));
  };

  const updateCursor = (row: number, col: number) => {
    socket.send(JSON.stringify({ type: "updateCursor", row, col }));
  };

  if (!currentUser) {
    return (
      <div className="room-container">
        <div className="room-header">
          <div className="room-header__left">
            <Logo />
          </div>
        </div>

        <NameInput onSubmit={handleNameSubmit} />
      </div>
    );
  }

  if (!gridState) {
    return <div>Loading...</div>;
  }

  return (
    <div className="room-container">
      <div className="room-header">
        <div className="room-header__left">
          <Logo />
        </div>
        <div className="room-header__right">
          {gridState.phase === "play" && (
            <div className="user-list">
              {users.map((user) => (
                <div key={user.id} className="user-indicator" title={user.name}>
                  <div
                    className="user-circle"
                    style={{ backgroundColor: getUserColor(user.name) }}
                  >
                    {user.name.slice(0, 2).toUpperCase()}
                  </div>
                </div>
              ))}
            </div>
          )}
          {gridState.phase === "setup" && (
            <div className="room-controls">
              <button className="btn btn-secondary" onClick={handleClearGrid}>
                Clear
              </button>
              <button className="btn btn-primary" onClick={startPlaying}>
                Start
              </button>
            </div>
          )}
        </div>
      </div>

      <CrosswordGrid
        gridState={gridState}
        gamePhase={gridState.phase}
        updateCell={updateCell}
        updateCursor={updateCursor}
        users={users}
        currentUser={currentUser}
      />
    </div>
  );
};

export default Room;
