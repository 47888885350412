import React from "react";
import { init } from "@paralleldrive/cuid2";
import CreateRoom from "./CreateRoom";
import { Logo } from "./Logo";

const createId = init({
  length: 6,
});

interface HomeProps {
  onRoomCreated: (roomId: string) => void;
  onRoomJoined: (roomId: string) => void;
}

const Home: React.FC<HomeProps> = ({ onRoomCreated, onRoomJoined }) => {
  const createRoom = async (size: number, roomName: string) => {
    const response = await fetch(
      `/party/${encodeURIComponent(`${roomName}-${createId()}`)}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ size }),
      }
    );
    const { roomId } = await response.json();
    onRoomCreated(roomId);
  };

  return (
    <div>
      <Logo />
      <CreateRoom onCreateRoom={createRoom} />
    </div>
  );
};

export default Home;
