import React, { useState } from "react";

interface CreateRoomProps {
  onCreateRoom: (size: number, roomName: string) => void;
}

const defaultName = `${new Date().toISOString().split("T")[0]}-DA`

const CreateRoom: React.FC<CreateRoomProps> = ({ onCreateRoom }) => {
  const [size, setSize] = useState(15);
  const [roomName, setRoomName] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onCreateRoom(size, roomName);
  };

  return (
    <div className="create-room">
      <h2>Create a new crossword</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="grid-size">Grid Size:</label>
          <input
            id="grid-size"
            type="text"
            value={size}
            onChange={(e) => setSize(Number(e.target.value))}
            min="5"
            max="20"
          />
        </div>
        <div className="form-group">
          <label htmlFor="room-name">Room Name:</label>
          <input
            id="room-name"
            type="text"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
            placeholder={defaultName}
            required
          />
        </div>
        <button type="submit">Create crossword</button>
      </form>
    </div>
  );
};

export default CreateRoom;
