import { useRef, useCallback, useState, useEffect, useMemo } from "react";

interface ClueNumber {
  number: number;
  horizontal: boolean;
  vertical: boolean;
}

interface UseCrosswordLogicProps {
  gridState: {
    size: number;
    cells: { value: string; isBlocked: boolean }[][];
  };
  gamePhase: "setup" | "play";
  updateCell: (
    row: number,
    col: number,
    cell: { value: string; isBlocked: boolean }
  ) => void;
  updateCursor: (row: number, col: number) => void;
  users: User[];
  currentUser: User | null;
}

type Direction = "horizontal" | "vertical";
type Position = [number, number];

export interface User {
  id: string;
  name: string;
  cursorPosition: { row: number; col: number } | null;
}

export const useCrosswordLogic = ({
  gridState,
  gamePhase,
  updateCell,
  updateCursor,
  users,
  currentUser,
}: UseCrosswordLogicProps) => {
  const inputRefs = useRef<HTMLInputElement[][]>(
    Array(gridState.size)
      .fill(null)
      .map(() => Array(gridState.size).fill(null))
  );

  const [currentCell, setCurrentCell] = useState<Position | null>(null);
  const [direction, setDirection] = useState<Direction>("horizontal");
  const [highlightedCells, setHighlightedCells] = useState<Position[]>([]);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStartCell, setDragStartCell] = useState<Position | null>(null);

  const isValidCell = useCallback(
    (row: number, col: number): boolean => {
      return (
        row >= 0 &&
        row < gridState.size &&
        col >= 0 &&
        col < gridState.size &&
        !gridState.cells[row][col].isBlocked
      );
    },
    [gridState.size, gridState.cells]
  );

  const isStartOfWord = useCallback(
    (row: number, col: number, dir: Direction): boolean => {
      if (!isValidCell(row, col)) return false;

      if (dir === "horizontal") {
        const isStart = col === 0 || !isValidCell(row, col - 1);
        const hasNextCell = isValidCell(row, col + 1);
        return isStart && hasNextCell;
      } else {
        const isStart = row === 0 || !isValidCell(row - 1, col);
        const hasNextCell = isValidCell(row + 1, col);
        return isStart && hasNextCell;
      }
    },
    [isValidCell]
  );

  const clueNumbers = useMemo(() => {
    const numbers: Record<string, ClueNumber> = {};
    let currentNumber = 1;

    for (let row = 0; row < gridState.size; row++) {
      for (let col = 0; col < gridState.size; col++) {
        if (isValidCell(row, col)) {
          const isHorizontalStart = isStartOfWord(row, col, "horizontal");
          const isVerticalStart = isStartOfWord(row, col, "vertical");

          if (isHorizontalStart || isVerticalStart) {
            numbers[`${row}-${col}`] = {
              number: currentNumber++,
              horizontal: isHorizontalStart,
              vertical: isVerticalStart,
            };
          }
        }
      }
    }

    return numbers;
  }, [gridState.size, isValidCell, isStartOfWord]);

  const getWordBoundaries = useCallback(
    (row: number, col: number, dir: Direction): [Position, Position] => {
      let start: Position = [row, col];
      let end: Position = [row, col];

      const [dRow, dCol] = dir === "horizontal" ? [0, 1] : [1, 0];

      // Find start of word
      while (isValidCell(start[0] - dRow, start[1] - dCol)) {
        start = [start[0] - dRow, start[1] - dCol];
      }

      // Find end of word
      while (isValidCell(end[0] + dRow, end[1] + dCol)) {
        end = [end[0] + dRow, end[1] + dCol];
      }

      return [start, end];
    },
    [isValidCell]
  );

  const isSingleLetterWord = useCallback(
    (row: number, col: number, dir: Direction): boolean => {
      const [start, end] = getWordBoundaries(row, col, dir);
      return end[0] - start[0] + 1 === 1 && end[1] - start[1] + 1 === 1;
    },
    [getWordBoundaries]
  );

  const getHighlightedCells = useCallback(
    (row: number, col: number, dir: Direction): Position[] => {
      const [start, end] = getWordBoundaries(row, col, dir);
      const highlighted: Position[] = [];

      if (dir === "horizontal") {
        for (let c = start[1]; c <= end[1]; c++) {
          highlighted.push([row, c]);
        }
      } else {
        for (let r = start[0]; r <= end[0]; r++) {
          highlighted.push([r, col]);
        }
      }

      return highlighted;
    },
    [getWordBoundaries]
  );

  const moveFocus = useCallback(
    (newRow: number, newCol: number) => {
      if (isValidCell(newRow, newCol)) {
        setCurrentCell([newRow, newCol]);
        inputRefs.current[newRow][newCol]?.focus();
      }
    },
    [isValidCell]
  );

  const handleCellClick = useCallback(
    (rowIndex: number, colIndex: number) => {
      if (gamePhase === "setup") {
        const cell = gridState.cells[rowIndex][colIndex];
        updateCell(rowIndex, colIndex, {
          ...cell,
          isBlocked: !cell.isBlocked,
          value: "",
        });
      } else if (gamePhase === "play") {
        if (!isValidCell(rowIndex, colIndex)) {
          return;
        }
        if (
          currentCell &&
          currentCell[0] === rowIndex &&
          currentCell[1] === colIndex
        ) {
          if (
            !isSingleLetterWord(
              rowIndex,
              colIndex,
              direction === "horizontal" ? "vertical" : "horizontal"
            )
          ) {
            setDirection((prev) =>
              prev === "horizontal" ? "vertical" : "horizontal"
            );
          }
          setCurrentCell([rowIndex, colIndex]);
        } else {
          if (isSingleLetterWord(rowIndex, colIndex, direction)) {
            setDirection((prev) =>
              prev === "horizontal" ? "vertical" : "horizontal"
            );
          }
          setCurrentCell([rowIndex, colIndex]);
        }
        moveFocus(rowIndex, colIndex);
        updateCursor(rowIndex, colIndex);
      }
    },
    [
      gamePhase,
      updateCell,
      currentCell,
      moveFocus,
      isSingleLetterWord,
      direction,
      updateCursor,
    ]
  );

  const handleKeyDown = useCallback(
    (rowIndex: number, colIndex: number, event: React.KeyboardEvent) => {
      if (gamePhase !== "play") return;

      const [dRow, dCol] = direction === "horizontal" ? [0, 1] : [1, 0];

      switch (event.key) {
        case "ArrowRight":
          if (direction === "vertical") {
            setDirection("horizontal");
          } else {
            moveFocus(rowIndex, colIndex + 1);
          }
          break;
        case "ArrowLeft":
          if (direction === "vertical") {
            setDirection("horizontal");
          } else {
            moveFocus(rowIndex, colIndex - 1);
          }
          break;
        case "ArrowDown":
          if (direction === "horizontal") {
            setDirection("vertical");
          } else {
            moveFocus(rowIndex + 1, colIndex);
          }
          break;
        case "ArrowUp":
          if (direction === "horizontal") {
            setDirection("vertical");
          } else {
            moveFocus(rowIndex - 1, colIndex);
          }
          break;
        case "Backspace":
          if (gridState.cells[rowIndex][colIndex].value === "") {
            moveFocus(rowIndex - dRow, colIndex - dCol);
            updateCell(rowIndex, colIndex, {
              ...gridState.cells[rowIndex][colIndex],
              value: "",
            });
          } else {
            updateCell(rowIndex, colIndex, {
              ...gridState.cells[rowIndex][colIndex],
              value: "",
            });
            moveFocus(rowIndex - dRow, colIndex - dCol);
          }
          break;
        default:
          if (event.key.length === 1 && /^[a-zA-Z]$/.test(event.key)) {
            updateCell(rowIndex, colIndex, {
              ...gridState.cells[rowIndex][colIndex],
              value: event.key.toUpperCase(),
            });
            moveFocus(rowIndex + dRow, colIndex + dCol);
          }
          break;
      }

      updateCursor(rowIndex, colIndex);
    },
    [gridState.cells, gamePhase, updateCell, direction, moveFocus, updateCursor]
  );

  const handleMouseDown = useCallback(
    (rowIndex: number, colIndex: number) => {
      if (gamePhase === "setup") {
        setIsDragging(true);
        setDragStartCell([rowIndex, colIndex]);
        handleCellClick(rowIndex, colIndex);
      } else if (gamePhase === "play") {
        handleCellClick(rowIndex, colIndex);
      }
    },
    [gamePhase, handleCellClick]
  );

  const handleMouseEnter = useCallback(
    (rowIndex: number, colIndex: number) => {
      if (gamePhase === "setup" && isDragging && dragStartCell) {
        const [startRow, startCol] = dragStartCell;
        const isBlocked = gridState.cells[startRow][startCol].isBlocked;
        updateCell(rowIndex, colIndex, {
          ...gridState.cells[rowIndex][colIndex],
          isBlocked: isBlocked,
          value: "",
        });
      }
    },
    [gamePhase, isDragging, dragStartCell, gridState.cells, updateCell]
  );

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
    setDragStartCell(null);
  }, []);

  useEffect(() => {
    if (currentCell && gamePhase === "play") {
      const [row, col] = currentCell;
      setHighlightedCells(getHighlightedCells(row, col, direction));
    }
  }, [currentCell, direction, gamePhase, getHighlightedCells]);

  return {
    inputRefs,
    handleCellClick,
    handleKeyDown,
    highlightedCells,
    currentCell,
    direction,
    clueNumbers,
    handleMouseDown,
    handleMouseEnter,
    handleMouseUp,
    users,
    currentUser,
  };
};
