export function getUserColor(userId: string, opacity: number = 1): string {
  // Simple hash function
  let hash = 0;
  for (let i = 0; i < userId.length; i++) {
    const char = userId.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }

  // Generate a hue value between 0 and 360
  const hue = Math.abs(hash % 360);

  // Return an HSLA color string
  return `hsla(${hue}, 70%, 60%, ${opacity})`;
}
