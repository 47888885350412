import React, { useState } from "react";

interface NameInputFormProps {
  onSubmit: (name: string) => void;
}

const NameInputForm: React.FC<NameInputFormProps> = ({ onSubmit }) => {
  const [name, setName] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(name);
  };

  return (
    <div className="join-room-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name-input">
            Enter your initials (2 characters):
          </label>
          <input
            id="name-input"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value.slice(0, 2).toUpperCase())}
            maxLength={2}
            required
            className="initials-input"
            placeholder="AB"
          />
        </div>
        <button
          type="submit"
          disabled={name.length === 0}
          className={`btn btn-primary ${
            name.length === 0 ? "btn-disabled" : ""
          }`}
        >
          Join Room
        </button>
      </form>
    </div>
  );
};

export default NameInputForm;
